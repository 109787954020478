import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: '首页',
		component: () => import('./views/Home.vue')
	},
	{
		path: '/home',
		name: '首页',
		component: () => import('./views/Home.vue')
	},
	{
		path: '/mcionStore',
		name: '魔豆商城',
		component: () => import('./components/McionStore.vue')
	},
	{
		path: '/myInfo',
		name: '我的',
		component: () => import('./components/MyInfo.vue')
	}, {
		path: '/paySuccess',
		name: '购买成功',
		component: () => import('./components/PaySuccess.vue')
	},
  {
		path: '/payError',
		name: '购买失败',
		component: () => import('./components/PayError.vue')
	}
]

const router = new VueRouter({
	// mode: 'history',
	routes,
	linkExactActiveClass: 'current',
	scrollBehavior() {
		return { x: 0, y: 0 }
	}
})

// router.beforeEach((to, from, next) => {
// })

export default router