<template>
  <ul class="footer hui">
    <li>
      <router-link to="/">首頁</router-link>
    </li>
    <li>
      <router-link to="/mcionStore">魔豆商城</router-link>
    </li>
    <li>
      <router-link to="/myInfo">我的</router-link>
    </li>
  </ul>
</template>
<script>
export default {
  name: "HomeBot",
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 1.2rem;
  max-width: 750px;
  min-width: 320px;
  margin: 0 auto;
  position: fixed;
  bottom: 0;
  z-index: 98;
  background: #ffffff;
  box-shadow: 0 0.1667rem 0.2667rem 0.1667rem #eeeeee;
  li {
    position: relative;
    flex: 1;
    height: 1.2rem;
    line-height: 1.2rem;
    a {
      display: inline-block;
      width: 100%;
      height: 100%;
      color: #999;
      padding-top: 0.35rem;
      transition: all 0.9s;
    }
  }
}
.hui {
  li {
    a {
      &::before {
        content: "";
        display: block;
        width: 0.64rem;
        height: 0.64rem;
        position: absolute;
        top: 0.09rem;
        left: 50%;
        transition: all 0.9s;
        transform: translate(-50%, 0);
      }
      &.current {
        color: #ffa019;
      }
    }
    &:nth-child(1) a::before {
      background: url("../assets/images/home_g.png");
      background-size: 100% 100%;
    }
    &:nth-child(2) a::before {
      background: url("../assets/images/store_g.png");
      background-size: 100% 100%;
    }
    &:nth-child(3) a::before {
      background: url("../assets/images/my_g.png");
      background-size: 100% 100%;
    }
    &:nth-child(1) a.current::before {
      background: url("../assets/images/home_o.png");
      background-size: 100% 100%;
    }
    &:nth-child(2) a.current::before {
      background: url("../assets/images/store_o.png");
      background-size: 100% 100%;
    }
    &:nth-child(3) a.current::before {
      background: url("../assets/images/my_o.png");
      background-size: 100% 100%;
    }
  }
  .orange {
    li {
      a {
        &::before {
          content: "";
          display: block;
          width: 0.64rem;
          height: 0.64rem;
          position: absolute;
          top: 0.09rem;
          left: 50%;
          transform: translate(-50%, 0);
        }
      }
      &:nth-child(1) a::before {
        background: url("../assets/images/home_o.png");
        background-size: 100% 100%;
      }
      &:nth-child(2) a::before {
        background: url("../assets/images/store_o.png");
        background-size: 100% 100%;
      }
      &:nth-child(3) a::before {
        background: url("../assets/images/my_o.png");
        background-size: 100% 100%;
      }
    }
  }
}
</style>
